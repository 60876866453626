<app-header></app-header>

<main class="lighting-container" id="lightingContainer">

    <section class="definition">
        <div class="container">
            <div class="wrapper">
                <div class="svg-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="140" height="160" viewBox="0 0 140 160" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M70.044 0L139.517 40.016V120.064L84.437 151.789V136.741L126.498 112.54V47.5399L70.044 15.0479L13.6862 47.5399V112.54L62.52 140.671V106.038L39.9002 119.042L22.4242 109.026L70.06 81.4856L117.776 109.026L100.284 119.042L77.6798 106.038V155.639L70.06 160L0.666992 120.064V40.016L70.044 0ZM70.044 73.9776L97.536 89.7923L113.463 80.639L70.044 55.6709L26.7053 80.639L42.552 89.7923L70.044 73.9776ZM70.044 48.4824L93.4306 61.901L106.881 54.1214L70.044 32.9233L33.2229 54.1374L46.7533 61.9169L70.044 48.4824Z"
                              fill="#598B3D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M70.0449 81.5001L117.761 109.04L100.285 119.04L77.6807 106.037C77.6807 122.538 77.6807 139.136 77.6807 155.638L70.0609 159.999V81.5001H70.0449ZM70.0449 73.9762V55.6695L113.479 80.6375L97.5529 89.7909L70.0449 73.9762ZM70.0449 48.481V32.9219L106.882 54.1359L93.4315 61.9155L70.0449 48.481Z"
                              fill="#6A9B40"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M13.6862 47.5399L70.044 15.0479V0L0.666992 40.016L13.6862 47.5399Z" fill="#7CAB40"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M13.6862 112.54V47.5396L0.666992 40.0156V120.064L13.6862 112.54Z" fill="#6A9B40"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M70.0449 15.0479L126.499 47.5399L139.518 40.016L70.0449 0V15.0479Z" fill="#8DB93E"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M126.499 47.5396V112.54L139.518 120.064V40.0156L126.499 47.5396Z" fill="#9FCB3D"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M126.496 112.541L84.4355 136.742V151.79L139.515 120.065L126.496 112.541Z"
                              fill="#B0D243"/>
                    </svg>
                </div>

                <div class="definition-subscription flex-center header-wrap">
                    <h1 class="header">Institutional Grade Staking Solutions</h1>
                    <p class="subtitle">Setting Standards in Blockchain,</p>
                    <p class="subtitle">Shaping Tomorrow's Green Landscape</p>
                </div>
            </div>
        </div>
        <div id="lottie"></div>
        <div class="follow-box" role="region" aria-labelledby="follow-box-title">
            <span style="position: absolute;
                         height: 1px; font-size: 1px;
                         display: block;
                         color: transparent"
                  id="follow-box-title"
            >
                Follow us on social media
            </span>

            <ul>
                <li>
                    <a [href]="ref.contacts.tg" target="_blank" aria-label="Follow us on Telegram">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-path"
                                  d="M9.83537 14.3134L9.52245 19.1044C9.98224 19.1044 10.1854 18.8895 10.442 18.6346L12.6511 16.4394L17.247 19.9093C18.0928 20.3863 18.7032 20.1393 18.9139 19.1069L21.931 4.57759C22.2398 3.31201 21.4589 2.738 20.6497 3.11341L2.93382 10.0945C1.72454 10.5901 1.73159 11.2796 2.71339 11.5868L7.2597 13.0369L17.7849 6.25102C18.2818 5.94311 18.7378 6.10865 18.3635 6.44812L9.83511 14.3132L9.83537 14.3134Z"
                                  fill="#B4BAC1"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a [href]="ref.contacts.md" target="_blank" aria-label="Follow us on Medium">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-path"
                                  d="M22.9726 2.98947L23 2.98346V2.77148H17.4092L12.218 14.9726L7.02677 2.77148H1.00201V2.98346L1.02904 2.98947C2.04953 3.2198 2.56761 3.5633 2.56761 4.8021V19.3049C2.56761 20.5437 2.04752 20.8872 1.02704 21.1175L1 21.1235V21.3362H5.08761V21.1242L5.06057 21.1182C4.04009 20.8878 3.522 20.5443 3.522 19.3055V5.64333L10.1907 21.3362H10.5689L17.4319 5.20569V19.6637C17.3444 20.6421 16.831 20.9442 15.907 21.1529L15.8796 21.1592V21.3695H23V21.1592L22.9726 21.1529C22.0476 20.9442 21.5219 20.6421 21.4344 19.6637L21.4297 4.8021H21.4344C21.4344 3.5633 21.9525 3.2198 22.9726 2.98947Z"
                                  fill="#B4BAC1"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a [href]="ref.contacts.fb" target="_blank" aria-label="Follow us on Facebook">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-path"
                                  d="M17.0098 13.25L17.5652 9.63047H14.0922V7.28164C14.0922 6.29141 14.5773 5.32617 16.1328 5.32617H17.7117V2.24453C17.7117 2.24453 16.2789 2 14.909 2C12.0488 2 10.1793 3.73359 10.1793 6.87187V9.63047H7V13.25H10.1793V22H14.0922V13.25H17.0098Z"
                                  fill="#B4BAC1"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a [href]="ref.contacts.x" target="_blank" aria-label="Follow us on X">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-path"
                                  d="M16.5843 4H19.2292L13.4509 10.6057L20.2486 19.5945H14.926L10.7572 14.1428L5.9871 19.5945H3.34061L9.52109 12.529L3 4H8.4577L12.226 8.98304L16.5843 4ZM15.656 18.0111H17.1216L7.66135 5.50027H6.08864L15.656 18.0111Z"
                                  fill="#B4BAC1"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a [href]="ref.contacts.yt" target="_blank" aria-label="Follow us on Youtube">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-path"
                                  d="M21.5821 7.2003C21.3521 6.33421 20.6744 5.65211 19.8139 5.42063C18.2542 5 12 5 12 5C12 5 5.74586 5 4.18613 5.42063C3.32565 5.65215 2.64794 6.33421 2.41792 7.2003C2 8.77014 2 12.0455 2 12.0455C2 12.0455 2 15.3208 2.41792 16.8906C2.64794 17.7567 3.32565 18.4104 4.18613 18.6419C5.74586 19.0625 12 19.0625 12 19.0625C12 19.0625 18.2541 19.0625 19.8139 18.6419C20.6744 18.4104 21.3521 17.7567 21.5821 16.8906C22 15.3208 22 12.0455 22 12.0455C22 12.0455 22 8.77014 21.5821 7.2003ZM9.95453 15.0192V9.07171L15.1818 12.0455L9.95453 15.0192Z"
                                  fill="#B4BAC1"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a [href]="ref.contacts.in" target="_blank" aria-label="Follow us on LinkId">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-path"
                                  d="M6.47689 22H2.33036V8.64702H6.47689V22ZM4.40139 6.82555C3.07547 6.82555 2 5.72732 2 4.40139C2 3.7645 2.253 3.1537 2.70335 2.70335C3.1537 2.253 3.7645 2 4.40139 2C5.03828 2 5.64909 2.253 6.09943 2.70335C6.54978 3.1537 6.80279 3.7645 6.80279 4.40139C6.80279 5.72732 5.72687 6.82555 4.40139 6.82555ZM21.996 22H17.8584V15.4999C17.8584 13.9507 17.8271 11.9641 15.7025 11.9641C13.5467 11.9641 13.2163 13.6471 13.2163 15.3882V22H9.07427V8.64702H13.0511V10.4685H13.1092C13.6628 9.41936 15.015 8.31219 17.0325 8.31219C21.229 8.31219 22.0004 11.0756 22.0004 14.665V22H21.996Z"
                                  fill="#B4BAC1"/>
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    </section>

    <app-blockchains-slider></app-blockchains-slider>

    <app-leaders-slider></app-leaders-slider>

    <section class="precision">
        <div class="container lighting" #lighting appLightingHover>
            <div class="wrap">

                <div class="title">
                    <h3 class="header">Global Validation, Local Precision</h3>
                    <p class="description">
                        Explore an Extensive Suite of Tools & Manuals
                        for Seamless Node Operations
                    </p>
                </div>

                <a [href]="ref.tools" target="_blank" class="btn btn-outlined btn-l"
                   aria-label="Explore Tools & Manuals" role="button">
                    Explore Tools & Manuals
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M3 12H21M21 12L16 7M21 12L16 17" stroke="#FAFAFA" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>

            </div>
        </div>
    </section>

    <app-discover></app-discover>

    <app-case></app-case>

    <app-team></app-team>

    <app-news></app-news>

    <app-baner></app-baner>

    <app-footer></app-footer>

</main>
