import { Component } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {DataRowOutlet} from "@angular/cdk/table";
import {RouterLink, RouterLinkActive} from "@angular/router";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    DataRowOutlet,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  public ref = environment.ref;

}
