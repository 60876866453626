import {Component, OnInit} from '@angular/core';
import {HeaderComponent} from "../../core/components/header/header.component";
import {FooterComponent} from "../../core/components/footer/footer.component";
import {BanerComponent} from "../../core/components/baner/baner.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgIf} from "@angular/common";
import {usernameValidator} from "../../core/validators/user-name.validator";
import {ContactUsService} from "./contact-us.service";
import {LightingHoverDirective} from "../../core/directives/lighting-hover.directive";
import {Router} from "@angular/router";

@Component({
    selector: 'app-contact-us',
    standalone: true,
    imports: [
        HeaderComponent,
        FooterComponent,
        BanerComponent,
        ReactiveFormsModule,
        NgIf,
        LightingHoverDirective
    ],
    templateUrl: './contact-us.component.html',
    styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent implements OnInit {

    contactForm: FormGroup;
    statusSending: boolean = false;
    openLoader: boolean = false;

    get username() {
        return this.contactForm.get('username');
    }

    get contact() {
        return this.contactForm.get('contact');
    }

    get email() {
        return this.contactForm.get('email');
    }

    get msg() {
        return this.contactForm.get('msg');
    }

    constructor(
        private fb: FormBuilder,
        private contactUsService: ContactUsService,
        private router: Router,
    ) {
        this.contactForm = this.fb.group({
            username: ['', [
                Validators.required,
                usernameValidator
            ]],
            contact: ['', [
                Validators.required,
            ]],
            company: ['',],
            email: ['', Validators.email],
            msg: ['', [
                Validators.required,
                Validators.min(12),
                Validators.max(300)
            ]],
        });
    }

    ngOnInit(): void {
    }

    onSubmit() {
        this.contactForm.disable();

        this.contactUsService.sendMessage(this.contactForm.value).subscribe({
            next: res => console.log('- sendMessage: ', res),
            complete: () => {
                this.openLoader = true;
                this.statusSending = true;
                this.contactForm.reset();
                this.contactForm.enable();
            },
            error: error => {
                console.log('- error: ', error)
                this.statusSending = false;
                this.openLoader = true;
                this.contactForm.reset();
                this.contactForm.enable();
            }
        })

        // this.contactForm.reset({}, this.contactForm.value);
    }

    handler() {
        if (this.statusSending) {
            this.router.navigate(['/']).then(r => r);
        } else {
            this.openLoader = false;
        }
    }
}
