import {Component, OnInit} from '@angular/core';
import {NgForOf, NgIf, NgStyle} from "@angular/common";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {LightingHoverDirective} from "../../directives/lighting-hover.directive";

@Component({
  selector: 'app-case',
  standalone: true,
    imports: [
        NgForOf,
        NgStyle,
        SlickCarouselModule,
        NgIf,
        LightingHoverDirective
    ],
  templateUrl: './case.component.html',
  styleUrl: './case.component.scss'
})
export class CaseComponent implements OnInit {
  casesList = [
    {
      title: 'Casper Labs',
      description: 'Forest Staking stands as the Casper network\'s largest and most efficient validator. \n' +
          'Our collaboration with Casper Labs further solidifies our reputation, \n' +
          'making us the go-to validator for unparalleled performance and reliability.',
      preview: 'case.png',
      id: 'slider-case1-label'
    },
    {
      title: 'Zetachain',
      description: 'Forest Staking stands as the Casper network\'s largest and most efficient validator. \n' +
          'Our collaboration with Casper Labs further solidifies our reputation, \n' +
          'making us the go-to validator for unparalleled performance and reliability.',
      preview: 'case_1.png',
      id: 'slider-case2-label'
    },
    {
      title: 'Aptos',
      description: 'Forest Staking shines on Aptos, setting benchmarks in reliability, uptime, and performance. \n' +
          'Our dedication to excellence positions us as a leading validator, \n' +
          'making us a trusted choice within the Aptos ecosystem.',
      preview: 'case_2.png',
      id: 'slider-case3-label'
    }
  ];

  slideConfig = {
    autoplay: this.casesList.length > 3,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    centerMode: false,
    infinite: true,
    cssEase: 'linear',
    easing: 'cubic-bezier(0.4, 0.01, 0.165, 0.99)',
    arrows: false,
    swipe: true,
    // centerPadding: '16px',
    variableWidth: true,

    keyboard: {
      enable: true,
      scrollAmount: 100,
      scrollType: "stepped"
    },

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          autoplay: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          variableWidth: true,
          centerMode: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          autoplay: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
        }
      },
      {
        breakpoint: 821,
        settings: "unslick"
      },
    ]
  };

  ngOnInit(): void {
  }


}
