import { Component } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {LightingHoverDirective} from "../../directives/lighting-hover.directive";

@Component({
  selector: 'app-baner',
  standalone: true,
    imports: [
        LightingHoverDirective
    ],
  templateUrl: './baner.component.html',
  styleUrl: './baner.component.scss'
})
export class BanerComponent {
  public ref = environment.ref.contacts;

}
