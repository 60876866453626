
<section class="case slider-list">
    <div class="container">

        <h2 class="header header-mobile">Case Studies</h2>

        <div class="wrapper list-wrapper" [attr.aria-roledescription]="'carousel'" aria-label="Case Studies Slider">

            <button class="btn btn-icon rotate"
                    [attr.role]="'button'"
                    [attr.aria-label]="'Previous Slide'"
                    [attr.aria-controls]="'slider-case'"
                    onclick="$('#caseList').slick('slickPrev')"
                    disabled="{{casesList.length > 3}}"
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="svg-path" d="M10 8L14 12L10 16" stroke="#FAFAFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>


            <ngx-slick-carousel
                #caseList
                id="caseList"
                class="list"
                [attr.role]="'group'"
                [attr.aria-live]="'polite'"
                [config]="slideConfig"
            >
                <ng-container *ngFor="let item of casesList">
                    <div [attr.role]="'tabpanel'"
                        [attr.aria-labelledby]="item.id"
                        ngxSlickItem
                    >
                        <div class="card lighting" #lighting appLightingHover>
                            <div class="wrap">
                                <div class="head" [ngStyle]="{'background-image': 'url(\'/assets/img/case/' + item.preview + '\')' }"></div>
                                <div class="content">
                                    <h3 id="{{item.id}}" class="header">
                                        {{ item.title }}
                                    </h3>
                                    <p>
                                        {{ item.description }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </ngx-slick-carousel>

<!--            <ul id="slider-case"-->
<!--                class="list"-->
<!--                role="group"-->
<!--                [attr.aria-live]="'polite'"-->
<!--            >-->
<!--                <ng-container *ngFor="let item of casesList; let i ">-->
<!--                    <li role="tabpanel" [attr.aria-labelledby]="item.id">-->
<!--                        <div class="card lighting">-->
<!--                            <div class="wrap">-->
<!--                                <div class="head" [ngStyle]="{'background-image': 'url(\'/assets/img/case/' + item.preview + '\')' }"></div>-->
<!--                                <div class="content">-->
<!--                                    <h3 id="{{item.id}}" class="header">-->
<!--                                        {{ item.title }}-->
<!--                                    </h3>-->
<!--                                    <p>-->
<!--                                        {{ item.description }}-->
<!--                                    </p>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ng-container>-->
<!--            </ul>-->

            <button class="btn btn-icon"
                    [attr.role]="'button'"
                    [attr.aria-label]="'Next Slide'"
                    [attr.aria-controls]="'slider-case'"
                    onclick="$('#caseList').slick('slickNext')"
                    disabled="{{casesList.length > 3}}"
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="svg-path" d="M10 8L14 12L10 16" stroke="#FAFAFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>

        </div>
    </div>
</section>
