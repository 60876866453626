import {ApplicationConfig, importProvidersFrom} from "@angular/core";
import {BrowserModule, provideClientHydration, withHttpTransferCacheOptions} from "@angular/platform-browser";
import {provideHttpClient, withFetch, withInterceptorsFromDi} from "@angular/common/http";
import {provideAnimations} from "@angular/platform-browser/animations";

import {ReactiveFormsModule} from "@angular/forms";

import {AppRoutingModule} from "./app-routing.module";

import {AppShellRenderDirective} from "./core/directives/app-shell-render.directive";
import {AppShellNoRenderDirective} from "./core/directives/app-shell-norender.directive";
import {LightingHoverDirective} from "./core/directives/lighting-hover.directive";


export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            AppRoutingModule,
            BrowserModule,
            ReactiveFormsModule
        ),
        AppShellRenderDirective,
        AppShellNoRenderDirective,
        LightingHoverDirective,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi(), withFetch()),
        provideClientHydration(withHttpTransferCacheOptions({includePostRequests: true}))
    ]
};
