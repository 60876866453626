import { AbstractControl, ValidationErrors } from '@angular/forms';

export function usernameValidator(control: AbstractControl): ValidationErrors | null {

    const value = control.value ? control.value : null;
    const errors: ValidationErrors = {};

    if(value) {
        if (!/^[\p{L}\s]*$/u.test(value)) {
            errors.invalidCharacters = true;
        }
        if (value.length < 2) {
            errors.tooShort = true;
        }
        if (value.length > 42) {
            errors.tooLong = true;
        }
    }

    return Object.keys(errors).length ? errors : null;
}