import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';

import { DOCUMENT, NgForOf, NgIf } from "@angular/common";
import { environment } from "../../../../environments/environment";
import { Subscription } from "rxjs";
import { WindowRefService } from "../../services/window-ref.service";
import {RouterLink} from "@angular/router";

export interface DataItem {
  ico: string | null;
  path: string;
  title: string;
  label: string;
}

@Component({
  selector: 'app-header',
  standalone: true,
    imports: [
        NgForOf,
        NgIf,
        RouterLink
    ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {

  public ref = environment.ref;

  isMenuOpen: boolean = false;
  isBlur: boolean = false;

  private scrollSubscription: Subscription;

  public navItems: Array<DataItem> = [
    {
      path: this.ref.serviceUrl,
      title: 'Services',
      ico: null,
      label: 'Go to Services'
    },
    // {
    //   path: this.ref.tools,
    //   title: 'Tools',
    //   ico: null,
    //   label: 'Go to Tools'
    // },
    {
      path: this.ref.news,
      title: 'News',
      ico: 'outputIco',
      label: 'Go to News'
    },
    {
      path: this.ref.contactUrl,
      title: 'Contact us',
      ico: null,
      label: 'To Contact Us',
    }
  ];

  constructor(
      private windowRef: WindowRefService,
      @Inject(DOCUMENT) private document: Document,
  ) {
  }

  private getScrollPosition(): number {
    return this.windowRef.nativeWindow.pageYOffset
        || this.windowRef.nativeWindow.scrollY
        || this.document.documentElement.scrollTop
        || this.document.body.scrollTop
        || 0;
  }

  openMenu = () => this.isMenuOpen = true;
  closeMenu = () => this.isMenuOpen = false;

  ngOnInit(): void {
    this.addBlur();
  }

  @HostListener('window:scroll', [])
  addBlur = () => this.isBlur = this.getScrollPosition() > 100;

  ngOnDestroy() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

}
