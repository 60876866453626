import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./layouts/home/home.component";
import {TermsComponent} from "./layouts/terms/terms.component";
import {ContactUsComponent} from "./layouts/contact-us/contact-us.component";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: HomeComponent,
  },
  {
    path: "contact-us",
    pathMatch: "full",
    component: ContactUsComponent,
  },
  {
    path: "legal",
    pathMatch: "full",
    component: TermsComponent,
  },
  {
    path: "**",
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
