

<section class="blockchains">
    <div class="container">
        <div class="navigation-wrap">
            <div class="title">Blockchains Supported</div>

            <div class="wrap" aria-roledescription="carousel" aria-label="Slider Blockchains Supported">
                <button class="btn btn-icon rotate prev-arrow"
                        id="slickPrev"
                        [attr.role]="'button'"
                        [attr.aria-label]="'Previous Slide'"
                        [attr.aria-controls]="'blockchainList'"
                        onclick="$('#blockchainList').slick('slickPrev')"
                        [disabled]="slidesLight"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-path" d="M10 8L14 12L10 16" stroke="#FAFAFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>

                <button class="btn btn-icon"
                        id="slickNext"
                        [attr.role]="'button'"
                        [attr.aria-label]="'Next Slide'"
                        [attr.aria-controls]="'blockchainList'"
                        onclick="$('#blockchainList').slick('slickNext')"
                        [disabled]="slidesLight"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-path" d="M10 8L14 12L10 16" stroke="#FAFAFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>


    <ngx-slick-carousel
            #blockchainList="slick-carousel"
            id="blockchainList"
            class="blockchain-list container"
            [attr.role]="'group'"
            [attr.aria-live]="'polite'"
            [config]="slideConfig"
    >
        <ng-container *ngFor="let slide of slides; trackBy: trackById">
            <div class="item"
                [attr.role]="'tabpanel'"
                [attr.aria-labelledby]="slide.labelledby"
                ngxSlickItem
            >
                <div class="blockchain-card">
                    <div class="coin-logo">
                        <div class="logo-wrap" [ngClass]="{'circle-white': !slide.logo}">
                            <img *ngIf="slide.logo" width="36" height="36" [src]="slide.logo" [alt]="slide.alt">
                        </div>
                        <span>{{ slide.title }}</span>
                    </div>
                    <div class="content-wrap">
                        <div class="info-box">
                            <div class="title">Price</div>
                            <div class="cost">${{ slide.price.cost }}</div>
                            <div class="state"
                                 [ngClass]="{green: slide.price.state >= 0, red: slide.price.state < 0}">
                                {{slide.price.state}}%
                            </div>
                        </div>
                        <div class="info-box">
                            <div class="title">FDV</div>
                            <div class="cost">${{ slide.fdv.cost }}</div>
                            <div class="state"
                                 [ngClass]="{green: slide.fdv.state >= 0, red: slide.fdv.state < 0}">
                                {{slide.fdv.state}}
                            </div>
                        </div>
                        <div class="info-box">
                            <div class="title">Market Cap</div>
                            <div class="cost">${{slide.marketCap.cost}}</div>
                            <div class="state"
                                 [ngClass]="{green: slide.marketCap.state >= 0, red: slide.marketCap.state < 0}">
                                {{slide.marketCap.state}}
                            </div>
                        </div>
                        <div class="info-box">
                            <button class="btn btn-outlined btn-s">Stake Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ngx-slick-carousel>

</section>
