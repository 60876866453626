import {Component, OnInit} from '@angular/core';
import {CommonModule, NgForOf, NgStyle} from "@angular/common";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {environment} from "../../../../environments/environment";
import {RrsService} from "./rrs.service";
import {LightingHoverDirective} from "../../directives/lighting-hover.directive";

@Component({
    selector: 'app-news',
    standalone: true,
    imports: [
        NgForOf,
        NgStyle,
        SlickCarouselModule,
        LightingHoverDirective,
        CommonModule,
    ],
    templateUrl: './news.component.html',
    styleUrl: './news.component.scss'
})
export class NewsComponent implements OnInit {
    public ref = environment.ref;

    public itemsWite = [
        {
            preview: 'img.png',
            title: 'A Traders’ Playbook – is a tactical turn in the USD upon us?',
            description: 'After 11 straight weeks of gains in the USD (DXY index), \n' +
                'driven by EURUSD trading into 1.0488, and a key weekly close above the YTD range highs',
            createAt: '10:05 / 06.07.2023',
            link: '#'
        },
        {
            preview: 'img_1.png',
            title: 'A Traders’ Playbook – is a tactical turn in the USD upon us?',
            description: 'After 11 straight weeks of gains in the USD (DXY index), \n' +
                'driven by EURUSD trading into 1.0488, and a key weekly close above the YTD range highs',
            createAt: '10:05 / 06.08.2023',
            link: '#'
        },
        {
            preview: 'img_2.png',
            title: 'A Traders’ Playbook – is a tactical turn in the USD upon us?',
            description: 'After 11 straight weeks of gains in the USD (DXY index), \n' +
                'driven by EURUSD trading into 1.0488, and a key weekly close above the YTD range highs',
            createAt: '10:05 / 06.09.2023',
            link: '#'
        }
    ];
    public itemsSlide = [];

    slideConfig = {
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
        infinite: true,
        cssEase: 'linear',
        easing: 'cubic-bezier(0.4, 0.01, 0.165, 0.99)',
        arrows: false,
        swipe: true,
        variableWidth: true,

        keyboard: {
            enable: true,
            scrollAmount: 100,
            scrollType: "stepped"
        },


        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    variableWidth: true,
                    centerMode: true,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    autoplay: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    // variableWidth: true,
                }
            },
            {
                breakpoint: 600,
                settings: "unslick"
            },
        ]
    };

    constructor(private rssService: RrsService) {
    }

    ngOnInit(): void {
        this.rssService.getMediumPosts().subscribe({
            next: result => {
                // @ts-ignore
                this.itemsSlide = [...result];
                console.log('feed : ', this.itemsSlide.length == 0 ? 'NULL' : this.itemsSlide);
            },
            error: err => console.log('- Error: ', err)
        });
    }

}
