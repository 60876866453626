import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { isPlatformBrowser} from '@angular/common';

@Directive({
  selector: '[appLightingHover]',
  standalone: true
})

export class LightingHoverDirective {
  @Input() minWidth: number = 992; // Default minimum width for effect

  constructor(
      private el: ElementRef,
      private renderer: Renderer2,
      @Inject(PLATFORM_ID) private platformId: Object) {};


  @HostListener('pointermove', ['$event'])
  onPointerMove(event: PointerEvent) {

    if (window.innerWidth > this.minWidth && isPlatformBrowser(this.platformId)) {
      const elements = document.getElementsByClassName('lighting');

      Array.from(elements).forEach((el: HTMLElement) => {

        const rect = el.getBoundingClientRect();

        el.style.setProperty("--x", (event.clientX - rect.left).toString());
        el.style.setProperty("--y", (event.clientY - rect.top).toString());
      });
    }
  }
}
