
<main class="terms">
    <app-header></app-header>
    <section class="container terms-descriptions">
        <div class="head">
            <h2 class="header">Terms & Policy</h2>
            <p>Welcome to Forest Staking. These terms and conditions outline the rules and regulations for the use of our website and services.
                By accessing this website, we assume you accept these terms and conditions in full.
                Do not continue to use Forest Staking's website if you do not accept all of the terms and conditions stated on this page.
            </p>
        </div>

        <ng-container *ngFor="let item of terms">
            <h6 class="header">{{ item.header }}</h6>
            <ng-container *ngIf="item.list">
                <ul>
                    <ng-container *ngFor="let text of item.list">
                        <li><p>{{ text }}</p></li>
                    </ng-container>
                </ul>
            </ng-container>
            <ng-container *ngIf="item.text">
                <p>{{ item.text }}</p>
            </ng-container>

        </ng-container>
    </section>
    <app-footer></app-footer>
</main>
