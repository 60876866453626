import { Component } from '@angular/core';
import {NgClass, NgForOf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {LightingHoverDirective} from "../../directives/lighting-hover.directive";

@Component({
  selector: 'app-discover',
  standalone: true,
    imports: [
        NgForOf,
        RouterLink,
        NgClass,
        LightingHoverDirective
    ],
  templateUrl: './discover.component.html',
  styleUrl: './discover.component.scss'
})
export class DiscoverComponent {
  public discoverList = [
    {
      header: 'Blockchain Infrastructure & RPC Provider',
      content: 'Power your blockchain applications with our top-tier infrastructure and lightning-fast RPC services. ' +
          'Gain reliable access to multiple networks, ensuring your operations run smoothly and efficiently.',
      ref: '#',
      logo: 'provider-logo.svg'
    },
    {
      header: 'Strategic Partners Onboarding',
      content: 'Build powerful alliances with ease through our streamlined partnership onboarding process. ' +
          'We provide comprehensive support to help you maximize the potential of every collaboration.',
      ref: '#',
      logo: 'onboarding-logo.svg'
    },
    {
      header: 'Validator Monitoring and Reporting Tools',
      content: 'Keep your validators in peak condition with our advanced monitoring and reporting tools. \n' +
          'Get real-time insights and detailed analytics to maintain optimal performance and security.',
      ref: '#',
      logo: 'tools-logo.svg'
    },
    {
      header: 'Dedicated Support and Relationship Management',
      content: 'Benefit from personalized support and expert relationship management tailored to your specific needs. ' +
          'Our dedicated team is here to ensure your blockchain operations are always running at their best.',
      ref: '#',
      logo: 'support-logo.svg'
    },
    {
      header: 'Cybersecurity Consultancy',
      content: 'Protect your blockchain assets with our specialized cyber security consultancy services. \n' +
          'We offer in-depth assessments and customized solutions to shield you from the latest threats.',
      ref: '#',
      logo: 'security-logo.svg'
    },
    {
      header: 'Whitelabel Infrastructure',
      content: 'Elevate your brand with our customizable whitelabel infrastructure. \n' +
          'Leverage our robust technology to offer top-tier blockchain services under your own brand identity.',
      ref: '#',
      logo: 'infrastructure-logo.svg'
    },
    {
      header: 'Market Making',
      content: 'Boost liquidity and market stability with our expert market-making services. \n' +
          'We provide strategic insights and precise execution to enhance your trading environment.',
      ref: '#',
      logo: 'market-logo.svg'
    },
    {
      header: 'Investment Strategies Advisory',
      content: 'Navigate the complexities of blockchain investments with confidence. \n' +
          'Our advisory services offer strategic guidance and market analysis to help you achieve your financial objectives.',
      ref: '#',
      logo: 'investment-logo.svg'
    },
    {
      header: 'Digital Product Design',
      content: 'Bring your digital product visions to life with our forthcoming product design & web3 development services. \n' +
          'Soon, you\'ll be able to create engaging, user-centric blockchain products with our innovative design solutions.',
      ref: '',
      logo: 'dpd-logo.svg'
    },
    {
      header: 'High Frequency Trading Tooling',
      content: 'Stay ahead in the trading game with our upcoming high-frequency trading tools. \n' +
          'Designed for speed and precision, these tools will help you optimize performance and profitability.',
      ref: '',
      logo: 'hf-tooling-logo.svg'
    },
  ];

}
