import {
    AfterViewInit,
    Component, ElementRef, HostListener,
    Inject,
    OnInit,
    PLATFORM_ID, Renderer2, RendererStyleFlags2,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';

import { Meta } from '@angular/platform-browser';

// Environments
import {environment} from "../../../environments/environment";

// Directives
import {LightingHoverDirective} from "../../core/directives/lighting-hover.directive";

// Components
import {HeaderComponent} from "../../core/components/header/header.component";
import {BlockchainsSliderComponent} from "../../core/components/blockchaines-slider/blockchains-slider.component";
import {LeadersSliderComponent} from "../../core/components/leaders-slider/leaders-slider.component";
import {DiscoverComponent} from "../../core/components/discover/discover.component";
import {CaseComponent} from "../../core/components/case/case.component";
import {TeamComponent} from "../../core/components/team/team.component";
import {NewsComponent} from "../../core/components/news/news.component";
import {BanerComponent} from "../../core/components/baner/baner.component";
import {FooterComponent} from "../../core/components/footer/footer.component";
import {WindowRefService} from "../../core/services/window-ref.service";


@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        HeaderComponent,
        BlockchainsSliderComponent,
        LeadersSliderComponent,
        DiscoverComponent,
        LightingHoverDirective,
        CaseComponent,
        TeamComponent,
        NewsComponent,
        BanerComponent,
        FooterComponent
    ]
})
export class HomeComponent implements OnInit, AfterViewInit {
    public ref = environment.ref;
    private lightingElements: ElementRef<unknown>[];
    private featureEls: NodeListOf<Element>;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document: Document,
        private meta: Meta,
        private el: ElementRef,
        private windowRef: WindowRefService,
        private renderer: Renderer2,
    ) {
        this.setOgMetaTags()
    }

    ngOnInit() {
        const lightingContainers = this.el.nativeElement.querySelectorAll('.lighting');
        this.lightingElements = Array.from(lightingContainers).map((container) => new ElementRef(container));
    }

    ngAfterViewInit() {
        this.featureEls = this.document.querySelectorAll(".lighting");
    }

    setOgMetaTags() {
        this.meta.addTags([
            { property: 'title', content: 'Forest Staking | Enterprise Level Validator Services', },
            { property: 'description', content: 'Forest Staking Enterprise-Grade Solutions | Stake with Professionals' },
            { property: 'og:site_name', content: 'Forest Staking' },
            { property: 'og:type', content: 'website' },
            { property: 'og:title', content: 'Forest Staking | Enterprise Level Validator Services' },
            { property: 'twitter:card', content: 'summary_large_image' },
            { property: 'twitter:title', content: 'Forest Staking | Enterprise Level Validator Services' },
            { property: 'twitter:description', content: 'Forest Staking Enterprise-Grade Solutions | Stake with Professionals' },
            { property: 'og:description', content: 'Forest Staking Enterprise-Grade Solutions | Stake with Professionals' },
            { property: 'og:image', content:  'https://static.wixstatic.com/media/3bfb60_8e32f12bd4e94b2bab2724306565ea7f~mv2.gif/v1/fill/w_518,h_518,al_c/3bfb60_8e32f12bd4e94b2bab2724306565ea7f~mv2.gif' },
            { property: 'og:image:width', content: '518' }, // Use current URL
            { property: 'og:image:height', content: '518' },
            { property: 'og:url', content: 'https://www.foreststaking.com' },
            // { property: 'og:url', content: window.location.href }, // Use current URL
        ]);
    }

    // @HostListener('window:pointermove', ['$event'])
    // onPointerMove(e: PointerEvent) {
    //     const featuresEl = this.document.querySelector('.lighting-container');
    //
    //     console.log('- lightingElements: ', this.lightingElements);
    //
    //     if (featuresEl && window.innerWidth > 992) {
    //         this.lightingElements.forEach((el: ElementRef) => {
    //             const rect = el.nativeElement.getBoundingClientRect();
    //             this.renderer.setStyle(el.nativeElement, '--x', `${e.clientX - rect.left}px`, RendererStyleFlags2.DashCase);
    //             this.renderer.setStyle(el.nativeElement, '--y', `${e.clientY - rect.top}px`, RendererStyleFlags2.DashCase);
    //         });
    //     }
    // }

}
