import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class RrsService {

    rssUrl = environment.ref.feed;

    constructor(
        private http: HttpClient,
        // private datePipe: DatePipe,
    ) {
    }

    getMediumPosts(): Observable<{
        preview: string;
        title: string;
        description: string;
        createAt: string;
        link: string;
    }[]> {
        const headers = new HttpHeaders({
            'mode': 'no-cors',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*/*',
            'Authorization': 'Bearer 2cd0a222afb9f1d594f3bef40a72f4bbd93b2807042bf96c0a1f89c7eeb933baf' // Замените YOUR_ACCESS_TOKEN на ваш токен
        });
        return this.http.get(this.rssUrl, {headers, responseType: 'text'})
            .pipe(
                map((response) => this.parseXML(response))
            );
    }

    formatDate = (dateStr: string): string => new Date(dateStr).toDateString();

    private parseXML(xml: string): any[] {
        console.log('@ parseXML: ');

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'application/xml');
        const items = xmlDoc.getElementsByTagName('item');
        const posts = [];

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const title = item.getElementsByTagName('title')[0].textContent;
            const link = item.getElementsByTagName('link')[0].textContent;
            const pubDate = item.getElementsByTagName('pubDate')[0].textContent;
            const description = item.getElementsByTagName('content:encoded')[0].textContent;

            const preview = 'img_3.png';

            console.log('@ parseXML: ', title);

            posts.push({title, link, createAt: this.formatDate(pubDate), description, preview});
        }

        return posts;
    }
}
