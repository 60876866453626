import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {map, Observable} from "rxjs";

export interface BlockchainInfo {
  id:number
  liquidity: number
  logo: string
  market_cap: number
  name: string
  price: number
  price_change_24h: number
  rank: number
  symbol: string
}

export interface BlockchainDesc {
  ath: number
  atl: number
  circulating_supply: string
  contracts: Array<any>
  id: number
  is_listed: boolean
  liquidity: number
  logo: string
  market_cap: number
  market_cap_diluted: number
  name: string
  off_chain_volume: number
  price: number
  price_change_1h: number
  price_change_1m: number
  price_change_1y:number
  price_change_7d: number
  price_change_24h: number
  rank: number
  symbol: string
  total_supply: string
  volume: number
  volume_7d: number
  volume_change_24h: number
}

@Injectable({
  providedIn: 'root'
})
export class BlockchainsService {
  baseUrl = 'https://api.mobula.io/api/1/';
  private apiKey = '3436f8c2-7bae-4033-ba5b-8cb823b989d6';
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${(this.apiKey)}` // Use Bearer token for authorization
  });
  queryParams = {
    fields: ['symbol', 'name', 'market_cap', 'price_change_24h', 'rank', 'liquidity', 'logo', 'price'] // Example query parameters
  };


  private blockchainList = [
    'Aptos', 'Casper', 'Berachain', 'Zetachain', 'Haqq', 'Sui', 'Axelar', 'Nibiru',
    'Canto', 'Persistence', 'Kujira', 'Tenet', 'Terra V2', 'Stride', 'Mars Protocol',
    'WhiteWhale', 'Planq', 'Acrechain', 'Oraichain', 'Provenance',
    "Meme Network", "Andromeda", 'Cheqd', 'Furya - CSK', 'Furya - MI', 'Source',
    'dwallet', 'Neutaro', 'Blockx', 'Seda', 'Pryzm'
  ];

  constructor(private http: HttpClient) {}


  getAllBlockchains(): Observable<BlockchainInfo[]> {
    const params = new HttpParams().set('fields', this.queryParams.fields.join(','));
    const url = `${this.baseUrl}all?${params.toString()}`;
    const headers = this.headers;

    return this.http.get<{ data: BlockchainInfo[] }>(url, { headers })
        .pipe(
            map(({data}): BlockchainInfo[] => {
              return data.filter(blockchain => this.blockchainList.includes(blockchain.name));
            }),
        );
  }

  getBlockchain(blockchain: string[]): Observable<{ data: {[key: string]: BlockchainDesc}}> {
    // const baseUrl = 'https://api.mobula.io/api/1/metadata?asset=Bitcoin';
    // const baseUrl = 'https://api.mobula.io/api/1/search?input=Aptos';
    // const baseUrl = 'https://api.mobula.io/api/1/market/data?asset=SOURCE';
    // const baseUrl = 'https://api.mobula.io/api/1/market/multi-data';

    const params = new HttpParams().set('assets', blockchain.join(','));
    const url = `${this.baseUrl}market/multi-data?${params.toString()}`;
    const headers = this.headers;

    return this.http.get<{ data: {[key: string]: BlockchainDesc}}>(url, { headers })
  }

}
