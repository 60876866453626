import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, TrackByFunction} from '@angular/core';
import { SlickCarouselModule } from "ngx-slick-carousel";
import {
  CurrencyPipe,
  DecimalPipe,
  isPlatformBrowser,
  JsonPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage
} from "@angular/common";
import { BlockchainCard } from "../../model/blockchain-card.model";
import {BlockchainDesc, BlockchainsService} from "./blockchains.service";


@Component({
  selector: 'app-blockchains-slider',
  standalone: true,
    imports: [
        SlickCarouselModule,
        NgForOf,
        JsonPipe,
        NgClass,
        CurrencyPipe,
        NgIf,
        NgOptimizedImage,
        DecimalPipe
    ],
  templateUrl: './blockchains-slider.component.html',
  styleUrl: './blockchains-slider.component.scss'
})
export class BlockchainsSliderComponent implements OnInit, AfterViewInit  {

  slides: BlockchainCard[] = [
    {
      id: 1,
      labelledby: 'APTOS',
      title: 'Aptos',
      logo: 'assets/img/coin-logo/aptos.svg',
      alt: 'Logo APTOS',
      price: {
        cost: 3.5,
        state: 1.9
      },
      fdv: {
        cost: 3.5,
        state: 14.97
      },
      marketCap: {
        cost: 3.5,
        state: 11.93
      },
    },
    {
      id: 2,
      labelledby: 'Casper',
      title: 'Casper',
      logo: 'assets/img/coin-logo/casper.png',
      alt: 'Logo Casper',
      price: {
        cost: 3.5,
        state: 1.9
      },
      fdv: {
        cost: 3.5,
        state: -14.97
      },
      marketCap: {
        cost: 3.5,
        state: -11.93
      },
    },
    {
      id: 3,
      labelledby: 'SUI',
      title: 'SUI',
      logo: 'assets/img/coin-logo/sui.svg',
      alt: 'Logo SUI',
      price: {
        cost: 3.5,
        state: 1.9
      },
      fdv: {
        cost: 3.5,
        state: 14.97
      },
      marketCap: {
        cost: 3.5,
        state: 11.93
      },
    },
    // {
    //   id: 4,
    //   labelledby: 'Zetachain',
    //   title: 'Zetachain',
    //   logo: 'assets/img/coin-logo/zetachain.png',
    //   alt: 'Logo Zetachain',
    //   price: {
    //     cost: 3.5,
    //     state: 1.9
    //   },
    //   fdv: {
    //     cost: 3.5,
    //     state: 14.97
    //   },
    //   marketCap: {
    //     cost: 3.5,
    //     state: 11.93
    //   },
    // },
  ];

  get slidesLight(): boolean {
    return this.slides.length <= 3
  }

  slideConfig = {
    autoplay: !this.slidesLight,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    centerMode: false,
    infinite: true,
    cssEase: 'linear',
    easing: 'cubic-bezier(0.4, 0.01, 0.165, 0.99)',
    arrows: false,
    swipe: true,
    centerPadding: '40px',
    nextArrow: '.prev-arrow',
    prevArrow: '#slickPrev',
    variableWidth: true,
    mouseWheel: {
      enable: true,
      scrollAmount: 200,
      preventDefault: true
    },
    keyboard: {
      enable: true,
      scrollAmount: 100,
      scrollType: "stepped"
    },
    documentTouchScroll: true,
    advanced: {
      updateOnContentResize: true
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        }
      },
    ]
  };

  constructor(
      @Inject(PLATFORM_ID) private platformId: Object,
      private blockchainsService: BlockchainsService
  ) {
  }

  ngOnInit(): void {
    // this.getAllBlockchains();
  }

  ngAfterViewInit() {
    isPlatformBrowser(this.platformId) && this.getAllBlockchains();
    this.slideConfig.autoplay = !this.slidesLight;
  }

  trackById: TrackByFunction<any> = (_, el) => el.id;

  getAllBlockchains(): void {
    this.blockchainsService.getAllBlockchains().subscribe({
      next: (res) => {
        this.getBlockchainList(res.map(blockchain => blockchain.name));
        },
      error: error => console.log('- getAllBlockchains Error: ', error)
    });
  }

  getBlockchainList(list: string[]): void {
    this.blockchainsService.getBlockchain(list).subscribe({
      next: ({data}) => {
        this.parseData(data);
        this.slideConfig.autoplay = !this.slidesLight
      },
      error: error => console.log('- getBlockchainList Error: ', error)
    });
  }

  parseData(blockchainData: { [key: string]: BlockchainDesc }) {
    Object.keys(blockchainData).map(key => {
      const {
        id,
        name,
        symbol,
        market_cap,
        logo,
        volume_change_24h,
        price,
        total_supply,
        circulating_supply,
        market_cap_diluted,
        price_change_24h
      } = blockchainData[key];

      const fdv = this.getFDV(total_supply, circulating_supply, price);
      const priceChange24h = this.priceChangePercentage(price_change_24h, price);

      this.generateData({
        id, name, symbol, market_cap, logo, volume_change_24h, price,
        total_supply, circulating_supply, market_cap_diluted, fdv, priceChange24h
      });

    });
  }

  formatNumber(num) {
    if (num < 1000) {
      return num;
    } else if (num < 1000000) {
      return (num / 1000).toFixed(1) + 'K';
    } else if (num < 1000000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num < 1000000000000) {
      return (num / 1000000000).toFixed(1) + 'B';
    } else if (num < 1000000000000000) {
      return (num / 1000000000000000).toFixed(1) + 'T';
    } else {
      return num.toFixed(1);
    }
  }

  priceChangePercentage(price_change: number, price: number): number {
    return ((price_change) / price) * 100;
  }

  getFDV(total_supply, circulating_supply, price): string {
    // Преобразование общего количества токенов и циркулирующего количества токенов в числа
    const totalSupply = parseFloat(total_supply);
    // const circulatingSupply = parseFloat(circulating_supply);
    // Расчет FDV с использованием общего количества токенов и цены
    let fdv = (totalSupply * price).toFixed(2);
    return this.formatNumber(fdv);
  }

  generateData(data): void {

    let card = {
      id: data.id,
      labelledby: data.name,
      title: data.name,
      logo: data.logo,
      alt: `Logo ${data.name}`,
      price: {
        cost: Math.floor(data.price).toFixed(2),
        state: data.volume_change_24h.toFixed(2)
      },
      fdv: {
        cost: data.fdv,
        state: 14.97
      },
      marketCap: {
        cost: this.formatNumber(data.market_cap),
        state: 11.93
      },
    };

    this.slides.push(card);

  }

}
