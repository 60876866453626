import { Component } from '@angular/core';
import { HeaderComponent } from "../../core/components/header/header.component";
import { FooterComponent } from "../../core/components/footer/footer.component";
import { NgForOf, NgIf } from "@angular/common";

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    NgForOf,
    NgIf
  ],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss'
})
export class TermsComponent {
  terms = [
    {
      header: 'Definitions',
      list: [
          'Company: Refers to Forest Staking.',
          'User: Refers to any individual or entity accessing or using the Company’s services.',
          'Services: Refers to all products and services provided by Forest Staking.'
      ],
    },
    {
      header: 'Acceptance of Terms',
      text: 'By accessing and using our Services, you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms, please do not use our Services.',
    },
    {
      header: 'Changes to Terms',
      text: 'Forest Staking reserves the right to modify these terms at any time. Changes will be effective immediately upon posting on our website. Your continued use of our Services following the posting of changes constitutes your acceptance of such changes.',
    },
    {
      header: 'Eligibility',
      text: 'To use our Services, you must be at least 18 years old or the legal age of majority in your jurisdiction. By using our Services, you represent and warrant that you meet these eligibility requirements.'
    },
    {
      header: 'Services',
      text: 'Forest Staking provides blockchain infrastructure services, including but not limited to staking services on various blockchain networks. We reserve the right to modify or discontinue any aspect of our Services at any time without notice.'
    },{
      header: 'User Responsibilities',
      text: 'Users are responsible for maintaining the confidentiality of their account information, including passwords and any activity under their account. Users agree to notify Forest Staking immediately of any unauthorized use of their account.'
    },{
      header: 'Prohibited Activities',
      list: [
          'Users agree not to engage in any of the following activities:',
          'Violating any applicable laws or regulations.',
          'Engaging in fraudulent or deceptive practices.',
          'Interfering with the proper functioning of our Services.',
          'Using our Services to distribute malware or other harmful code.',
      ]
    },
    {
      header: 'Intellectual Property',
      text: 'All content, trademarks, and other intellectual property on our website are the property of Forest Staking or its licensors. Users are granted a limited license to access and use the content for personal, non-commercial purposes.'
    },
    {
      header: 'Privacy Policy',
      text: 'Our Privacy Policy governs the collection, use, and disclosure of your personal information. By using our Services, you consent to the practices described in our Privacy Policy.'
    },
    {
      header: 'Limitation of Liability',
      text: 'Forest Staking shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of our Services. This includes, but is not limited to, damages for loss of profits, data, or other intangible losses.'
    },
    {
      header: 'Indemnification',
      text: 'Users agree to indemnify and hold Forest Staking harmless from any claims, damages, liabilities, and expenses (including legal fees) arising out of their use of our Services or violation of these terms.'
    },
    {
      header: 'Contact Information',
      text: 'For any questions or concerns regarding these terms and conditions, please contact us.'
    }
  ];
}
