
<section class="leaders">
    <div class="header-wrap">
        <h2 class="header">
            Tailored for Layer1 Pioneers, Trusted by Industry Leaders
        </h2>
    </div>

    <div class="container" [attr.role]="'region'" [attr.aria-label]="'Our Partners'">
        <ngx-slick-carousel
            #partnersTopList
            id="partnersTopList"
            class="brand-list"
            [attr.role]="'listbox'"
            [attr.aria-label]="'Partners List'"
            [config]="slideConfig"
            (beforeChange)="beforeChange()"
        >
            <ng-container *ngFor="let card of topLineSlide">
                <div [attr.aria-label]="card.alt"
                     [attr.aria-selected]="false"
                     [attr.role]="'option'"
                     [attr.aria-hidden]="false"
                     ngxSlickItem
                >
                    <div class="item">
                        <img src="{{card.logo}}" [alt]="card.alt">
                    </div>
                </div>
            </ng-container>
        </ngx-slick-carousel>

        <ngx-slick-carousel
            #partnersBottomList
            id="partnersBottomList"
            class="brand-list bottom-list"
            [attr.role]="'listbox'"
            [attr.aria-label]="'Partners List'"
            [config]="slideConfig"
        >
            <ng-container *ngFor="let card of bottomLineSlide">
                <div [attr.aria-label]="card.alt"
                     [attr.aria-selected]="false"
                     [attr.role]="'option'"
                     [attr.aria-hidden]="false"
                     ngxSlickItem
                >
                    <div class="item">
                        <img src="{{card.logo}}"  [alt]="card.alt">
                    </div>
                </div>
            </ng-container>

        </ngx-slick-carousel>
    </div>

</section>
