
<section class="discover">
    <h2 class="header">
        Discover what lays in the Forest
    </h2>

    <div class="container">

        <p class="description">
            Forest staking optimizes blockchain infrastructure, offering secure, scalable solutions. It empowers projects, addressing varied needs, ensuring efficiency, and providing expert guidance, making it an invaluable asset in the blockchain service industry
        </p>

        <ul class="wrapper" [attr.role]="'list'">
            <ng-container *ngFor="let card of discoverList">
                <li [attr.role]="'listitem'">
                    <div class="discover-card lighting" #lighting appLightingHover>
                        <div class="wrap">
                            <div class="title">
                                <div class="icon-wrap" [attr.aria-hidden]="true" [attr.role]="'img'">
                                    <img src="{{'/assets/img/discover/' + card.logo}}" width="64" height="64" [alt]="">
                                </div>
                                <h3 class="header" [attr.aria-level]="3">{{card.header}}</h3>
                            </div>
                            <p class="content">
                                {{card.content}}
                            </p>
<!--                            <a routerLink="{{card.ref}}"-->
<!--                               class="btn btn-text"-->
<!--                               [ngClass]="{disabled: !card.ref}"-->
<!--                               [attr.aria-label]="'Learn more about ' + card.header"-->
<!--                               [attr.role]="'button'"-->
<!--                            >-->
<!--                                {{ card.ref ? 'Learn more' : 'Coming soon'}}-->

<!--                                <div class="arrow-container" [attr.aria-hidden]="true" [attr.role]="'img'">-->
<!--                                    <svg class="arrow" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                        <path class="svg-path" d="M15 10L20 15L15 20" stroke="#FAFAFA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                        <path class="line svg-path" d="M10 15H18" stroke="#FAFAFA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                    </svg>-->
<!--                                </div>-->
<!--                            </a>-->
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>

    </div>

</section>
