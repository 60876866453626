import {Component, OnInit} from '@angular/core';
import {SlickCarouselModule} from "ngx-slick-carousel";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-leaders-slider',
  standalone: true,
  imports: [
    SlickCarouselModule,
    NgForOf
  ],
  templateUrl: './leaders-slider.component.html',
  styleUrl: './leaders-slider.component.scss'
})
export class LeadersSliderComponent implements OnInit {

  topLineSlide = null;
  bottomLineSlide = null;
  slides = [
    {
      logo: 'assets/img/partners/logo01.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo02.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo03.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo04.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo05.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo06.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo07.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo08.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo09.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo10.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo11.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo12.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo13.png',
      alt: 'Partners logo',
    },
    {
      logo: 'assets/img/partners/logo14.png',
      alt: 'Partners logoPartners logo',
    },
  ];

  slideConfig = {
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 14000,
    autoplaySpeed: 0,
    dots: false,
    centerMode: false,
    infinite: true,
    cssEase: 'linear',
    easing: 'cubic-bezier(0.4, 0.01, 0.165, 0.99)',
    arrows: false,
    swipe: true,
    variableWidth: true,
    keyboard: {
      enable: true,
      scrollAmount: 100,
      scrollType: "stepped"
    },
    documentTouchScroll: true,
    advanced: {
      updateOnContentResize: true
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        }
      },
    ]
  };

  ngOnInit(): void {
    this.topLineSlide = this.slides.filter((el, i) => i%2);
    this.bottomLineSlide = this.slides.filter((el, i) => !(i%2));
  }

  beforeChange() {
    // console.log('-- beforeChange: ', e);
  }
}
