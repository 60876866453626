import { Component, Inject, OnInit } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';

import { isPlatformBrowser, NgClass } from "@angular/common";
import { DOCUMENT } from '@angular/common';

import { NavigationEnd, RouterLink, RouterOutlet, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        RouterOutlet,
        NgClass,
    ]
})
export class AppComponent implements OnInit {
    showBackground: boolean = true;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private titleService: Title
    ) {

    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.document.onreadystatechange = function () {
                if (document.readyState == 'complete') {

                }
            }

            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    // Проверяем текущий маршрут и устанавливаем значение showBackground
                    this.showBackground = event.urlAfterRedirects === '/';
                    window.scrollTo(0, 0);

                    event.urlAfterRedirects.includes('/' ) && this.setTitle('Forest Staking');
                    event.urlAfterRedirects.includes('/contact-us' ) && this.setTitle('Forest Staking | Contact Us');
                    event.urlAfterRedirects.includes('/legal' ) && this.setTitle('Forest Staking | Terms & Policy');

                }
            });
        }
    }

    setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

}
