import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ContactUsService {

    mailerPath = environment.ref.mailer;

    constructor(private http: HttpClient) {
    }

    sendMessage(body: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        return this.http.post(this.mailerPath, body, {headers});
    }
}
